'use client';

import React from 'react';
import GenericImage from './components/elements/GenericImage/GenericImage';
import ErrorDTMEvent from './components/ErrorDTMEvent/ErrorDTMEvent';
import { fsSetProperties } from './script/FullStoryScript';
import { usePathname } from 'next/navigation';
import { isPdpPage } from './utils/PDPHelper';
import { isHomePage, isPlpPage } from './utils/common-utils';

interface ErrorProps {
  error: Error & { digest?: string };
  reset: () => void;
}

const NextError = ({ error }: ErrorProps) => {
  const pathname = usePathname();
  const page = isPdpPage(pathname)
    ? 'pdp'
    : isPlpPage(pathname)
      ? 'plp'
      : isHomePage(pathname)
        ? 'home'
        : 'Page Not Found';

  console.error(error);

  if (window && window?.FS) {
    fsSetProperties({ oopsErrorOnPage: page });
  }

  return (
    <>
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center mt-3"
        data-att="error-page"
      >
        <div className="mr-2">
          <GenericImage
            alt="404 Page"
            className="visible-sm "
            src="https://img.lakeshorelearning.com/is/image/OCPreproduction/oops-girl?wid=640&amp;qlt=85,1&amp;fmt=gif&amp;pscan=auto"
            width="320"
            height="420"
          />
        </div>
        <div style={{ marginLeft: '50px' }}>
          <p
            style={{
              letterSpacing: '-.015em',
              color: '#dd3e49',
              fontSize: '80px',
              fontWeight: 'bold',
              lineHeight: '100px',
              marginBottom: '20px',
            }}
          >
            Oops!
          </p>
          <p
            style={{
              letterSpacing: '-0.015em',
              color: '#020202',
              fontSize: '18px',
              lineHeight: '27px',
              marginBottom: '20px',
            }}
          >
            Sorry! We can&rsquo;t seem to find the page you&rsquo;re looking
            for.
          </p>
          <p
            style={{
              letterSpacing: '-0.015em',
              color: '#020202',
              fontSize: '18px',
              lineHeight: '27px',
            }}
          >
            Please{' '}
            <a className="blue" href="/" data-updated="false">
              click here
            </a>{' '}
            to continue.
          </p>
        </div>
      </div>
      <ErrorDTMEvent />
    </>
  );
};

export default NextError;
